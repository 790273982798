<smv-form-field-wrapper [formControl]="heatmapWeightControl" controlType="select" [selectOptions]="attributes" required>
  <span mat-label i18n="Layer heatmap">Pondération</span>
</smv-form-field-wrapper>

<smv-form-field-wrapper [formControl]="heatmapRadiusControl" type="number">
  <span mat-label i18n="Layer heatmap">Rayon (en pixels)</span>
</smv-form-field-wrapper>

<smv-form-field-wrapper [formControl]="heatmapBlurControl" type="number">
  <span mat-label i18n="Layer heatmap">Flou (en pixels)</span>
</smv-form-field-wrapper>
