import { Injectable } from '@angular/core';
import { WidgetModel, WidgetType } from '@core/model/application-api/widget.model';
import { ContextItem } from '@core/model/context-item.model';
import { GeneralUtils } from '@core/utils/general.utils';

export type WidgetsVisibility = {
  [widget in WidgetType]: boolean;
};

@Injectable({ providedIn: 'root' })
export class WidgetService {
  private readonly widgetState: Map<WidgetType, ContextItem<WidgetModel | undefined>> = new Map();
  public readonly widgetVisibilityState = new ContextItem<WidgetsVisibility>({
    [WidgetType.LAYERS_MANAGER]: true,
    [WidgetType.SCALE]: false,
    [WidgetType.COORDINATES]: false,
    [WidgetType.SEARCH]: false,
    [WidgetType.TIMELINE]: false,
    [WidgetType.LEGEND]: false,
    [WidgetType.MEASURE_LENGTH]: false,
    [WidgetType.MEASURE_AREA]: false,
    [WidgetType.DATEPICKER]: false,
    [WidgetType.VIGIEVIROSE]: false,
    [WidgetType.QUALIH2O]: false,
    [WidgetType.ICARE]: false,
    [WidgetType.ICARE_ADMIN]: false,
    [WidgetType.VISIOVOL]: false,
    [WidgetType.VIGIEVIROSE_ADMIN]: false,
    [WidgetType.QUALICIBLE_CNC]: false,
    [WidgetType.OPTI_SEMIS]: false,
    [WidgetType.INTERRA_SCAN]: false,
  });
  public readonly widgetLocalVisibilityState = new ContextItem<WidgetsVisibility>({
    [WidgetType.LAYERS_MANAGER]: true,
    [WidgetType.SCALE]: true,
    [WidgetType.COORDINATES]: true,
    [WidgetType.SEARCH]: true,
    [WidgetType.TIMELINE]: true,
    [WidgetType.LEGEND]: true,
    [WidgetType.MEASURE_LENGTH]: true,
    [WidgetType.MEASURE_AREA]: true,
    [WidgetType.DATEPICKER]: true,
    [WidgetType.VIGIEVIROSE]: true,
    [WidgetType.QUALIH2O]: true,
    [WidgetType.ICARE]: true,
    [WidgetType.ICARE_ADMIN]: true,
    [WidgetType.VISIOVOL]: false,
    [WidgetType.VIGIEVIROSE_ADMIN]: true,
    [WidgetType.QUALICIBLE_CNC]: false,
    [WidgetType.OPTI_SEMIS]: true,
    [WidgetType.INTERRA_SCAN]: true,
  });

  public resetContext() {
    this.widgetVisibilityState.reset();
    this.widgetState.clear();
  }

  public updateWidgetState(widgets?: WidgetModel[]) {
    this.widgetState.forEach((widgetContext) => widgetContext.setValue(undefined));
    widgets?.forEach((widget) => {
      this.getWidgetContextItem(widget.widgetId).setValue(widget);
      this.widgetVisibilityState.setParticularValue(widget.widgetId, widget.visible);
      this.widgetLocalVisibilityState.setParticularValue(widget.widgetId, widget.visible);
    });
  }

  public getWidgetContextItem(widgetType: WidgetType): ContextItem<WidgetModel | undefined> {
    if (!this.widgetState.has(widgetType)) {
      this.widgetState.set(widgetType, new ContextItem<WidgetModel | undefined>(undefined));
    }
    return this.widgetState.get(widgetType) ?? new ContextItem<WidgetModel | undefined>(undefined);
  }

  public getWidgetsState(): WidgetModel[] {
    return Array.from(this.widgetState.values())
      .map((widget) => widget.getValue())
      .filter(GeneralUtils.isNotNull);
  }

  public getVisibleWidgets(): WidgetModel[] {
    const visibility = this.widgetVisibilityState.getValue();
    return this.getWidgetsState().filter((widget) => visibility[widget.widgetId]);
  }
}
