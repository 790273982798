import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { WidgetType } from '@core/model/application-api/widget.model';
import { WmsLayerConfig } from '@core/model/layer-config.model';
import { MapService } from '@core/services/map.service';
import { DataLayerConfig } from '@feature/client-app/data-layer-config.model';
import { Qualih2oService } from '@feature/client-app/widgets/quali-h2o/qualih2o.service';
import TileWMS from 'ol/source/TileWMS';
import { Subscription } from 'rxjs';

@Component({
  selector: 'smv-wms-legend',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './wms-legend.component.html',
  styleUrls: ['./wms-legend.component.scss'],
})
export class WmsLegendComponent implements OnChanges, OnDestroy {
  @Input() layer?: DataLayerConfig;

  public legendUrls: string[] = [];

  private dynamicLegend?: Subscription;

  constructor(private mapService: MapService, private qualih2oService: Qualih2oService) {}

  ngOnChanges(): void {
    this.dynamicLegend?.unsubscribe();

    if (this.layer?.config?.fromWidget === WidgetType.QUALIH2O) {
      this.dynamicLegend = this.qualih2oService.observeWmsLegend(this.layer).subscribe((legends) => {
        this.setLegendImageUrls(legends);
      });
    } else {
      const layerSource = this.layer?.olLayer?.getSource();
      if (layerSource && this.layer?.config instanceof WmsLayerConfig) {
        const legends = this.layer.config.getLegendUrl(layerSource as TileWMS, this.mapService.getScale());
        this.setLegendImageUrls(legends);
      }
    }
  }

  ngOnDestroy(): void {
    this.dynamicLegend?.unsubscribe();
  }

  private setLegendImageUrls(legends: string | string[]) {
    const legendsAuth = Array.isArray(legends) ? legends : [legends];
    for (let i = 0; i < legendsAuth.length; i++) {
      const key = legendsAuth[i].includes('?') ? '&Authorization=' : '?Authorization=';
      legendsAuth[i] += key + sessionStorage.getItem('Authorization');
    }
    this.legendUrls = legendsAuth;
  }
}
