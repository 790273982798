import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OlGmapModule } from '@syngenta/ol-gmap';

import { CalendarComponent } from '@widgets/calendar/calendar.component';
import { SearchAddressComponent } from '@widgets/search-address/search-address.component';
import { TimelineComponent } from '@widgets/timeline/timeline.component';
import { ClientAppComponent } from './client-app.component';
import { EditionValidationModule } from './edition-validation/edition-validation.module';
import { FeatureDetailsComponent } from './feature-details/feature-details.component';
import { GeometryEditionComponent } from './geometry-edition/geometry-edition.component';
import { DataLayersModule } from './layers/data-layers.module';
import { LegendComponent } from './legend/legend.component';
import { ApplicationSettingsModule } from './settings/settings.module';
import { IcareAdminModule } from './widgets/icare-admin/icare-admin.module';
import { IcareModule } from './widgets/icare/icare.module';
import { InterraScanModule } from './widgets/interra-scan/interra-scan.module';
import { OptiSemisModule } from './widgets/opti-semis/opti-semis.module';
import { QualiH2oModule } from './widgets/quali-h2o/quali-h2o.module';
import { VigieViroseAdminModule } from './widgets/vigie-virose-admin/vigie-virose-admin.module';
import { VigieViroseModule } from './widgets/vigie-virose/vigie-virose.module';

@NgModule({
  declarations: [ClientAppComponent],
  imports: [
    CommonModule,
    DataLayersModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    OlGmapModule,
    TimelineComponent,
    CalendarComponent,
    SearchAddressComponent,
    FeatureDetailsComponent,
    LegendComponent,
    GeometryEditionComponent,
    EditionValidationModule,
    ApplicationSettingsModule,
    QualiH2oModule,
    VigieViroseModule,
    VigieViroseAdminModule,
    IcareModule,
    IcareAdminModule,
    OptiSemisModule,
    InterraScanModule,
  ],
})
export class ClientAppModule {}
