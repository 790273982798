import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LayerEditType, LayerSubtype } from '@core/model/application-api/layer.model';
import { ParameterId, WidgetModel, WidgetType } from '@core/model/application-api/widget.model';
import { Application, ApplicationConfig } from '@core/model/application.model';
import { WfsLayerConfig } from '@core/model/layer-config.model';
import { ProfileCode } from '@core/model/right.model';
import { User } from '@core/model/user.model';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { AuthService } from '@core/services/auth.service';
import { MapService } from '@core/services/map.service';
import { NavigationService } from '@core/services/navigation.service';
import { NotificationService } from '@core/services/notification.service';
import { SelectionService } from '@core/services/selection.service';
import { parseLayers, rebuildZIndexes } from '@core/utils/app-config-utils';
import { MapUtils } from '@core/utils/map.utils';
import {
  MeasureType,
  OlGmapComponent,
  OlGmapDefaultConfiguration,
  OlGmapGeolocationButtonComponent,
} from '@syngenta/ol-gmap';
import { Qualih2oService } from '@widgets/quali-h2o/qualih2o.service';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';
import { WidgetService, WidgetsVisibility } from '@widgets/widgets.service';
import { DataLayerConfig, DataLayerGroupConfig } from './data-layer-config.model';
import { SpecifierInformationComponent } from './specifier-information/specifier-information.component';

import { isNil } from 'lodash-es';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Subscription, debounceTime, delay, finalize, map, of, retry } from 'rxjs';
import { InterraScanService } from './widgets/interra-scan/interra-scan.service';

interface SidePanels {
  dataLayers: boolean;
  dataLayerEditor: boolean;
  layerPropertyViewer: boolean;
  featureDetails: boolean;
  legend: boolean;
  widgetVigieVirose: boolean;
  widgetVigieViroseAdmin: boolean;
  widgetQualiH2O: boolean;
  widgetIcare: boolean;
  widgetIcareAdmin: boolean;
  widgetOptiSemis: boolean;
  widgetInterraScan: boolean;
  settings: boolean;
  editionValidation: boolean;
}

@Component({
  selector: 'smv-client-app',
  templateUrl: './client-app.component.html',
  styleUrls: ['./client-app.component.scss', './client-app-panel.scss'],
})
export class ClientAppComponent implements OnInit, OnDestroy {
  @ViewChild(OlGmapComponent) olGmap?: OlGmapComponent;
  @ViewChild(OlGmapGeolocationButtonComponent) geolocation?: OlGmapGeolocationButtonComponent;

  public MeasureType = MeasureType;

  public mapConfig = { ...OlGmapDefaultConfiguration };
  public mapReady = false;
  public displayOverlay = false;
  public application?: Application;
  public configuration?: ApplicationConfig;
  public layerGroups: DataLayerGroupConfig[] = [];
  public layerToEdit?: DataLayerConfig;
  public layerToValid?: DataLayerConfig;

  public sidePanels: SidePanels = {
    dataLayers: false,
    dataLayerEditor: false,
    layerPropertyViewer: false,
    featureDetails: false,
    legend: false,
    widgetVigieVirose: false,
    widgetVigieViroseAdmin: false,
    widgetQualiH2O: false,
    widgetIcare: false,
    widgetIcareAdmin: false,
    widgetOptiSemis: false,
    widgetInterraScan: false,
    settings: false,
    editionValidation: false,
  };
  public panelTogglesVisible = true;
  public activePanel?: keyof SidePanels;
  public activeLayer?: DataLayerConfig;
  public isFeatureSelected = false;
  public hasLegendAvailable = false;
  public canUserModifyLayer = false;
  public canUserEditLayer = false;
  public canUserValidateLayer = false;
  public isUserApplicationAdmin = false;
  public isSavingDisplay = false;

  public widgetsVisibility?: WidgetsVisibility;
  public widgetsLocalVisibility?: WidgetsVisibility;
  public visibleWidgets: WidgetModel[] = [];

  public activeMeasure = {
    length: false,
    area: false,
  };
  public measureMessage = {
    begin: $localize`Cliquez pour commencer à mesurer`,
    continueLength: $localize`Cliquez pour continuer la mesure de distance`,
    continueArea: $localize`Cliquez pour continuer la mesure de surface`,
  };
  public position = {
    projection: MapUtils.PROJECTION_CODE_OPENLAYERS,
    template: 'Lat : {y}° | Lon : {x}° (WGS84)',
    digits: 4,
  };
  public WidgetType = WidgetType;

  private currentUser?: User;
  private latestPanel?: keyof SidePanels;
  private visibilityStorageKey = 'app-undefined-widgets-visibility';
  private initWidgetVisibility = true;
  private dialogSpecifier?: MatDialogRef<SpecifierInformationComponent>;
  private subscriptions = new Subscription();

  constructor(
    private applicationApi: ApplicationApiService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private navigation: NavigationService,
    private widgetService: WidgetService,
    private mapService: MapService,
    private selectionService: SelectionService,
    private qualih2oService: Qualih2oService,
    private interraScanService: InterraScanService,
    private geoserverVariables: GeoserverVariablesService,
    private dialog: MatDialog
  ) {
    this.mapConfig.maxZoomLevel = 20;
    this.subscriptions.add(
      this.applicationApi.settingsPanelState.getStream().subscribe((state) => {
        if (state) {
          this.latestPanel = this.activePanel;
          this.togglePanel('settings');
        } else if (this.latestPanel) {
          this.togglePanel(this.latestPanel);
          this.latestPanel = undefined;
        } else if (this.activePanel === 'settings') {
          this.togglePanel('settings');
        }
      })
    );
    this.subscriptions.add(
      this.widgetService.widgetVisibilityState.getStream().subscribe((widgets) => {
        this.widgetsVisibility = widgets;
        this.visibleWidgets = this.widgetService.getVisibleWidgets();
      })
    );
    this.subscriptions.add(
      this.widgetService.widgetLocalVisibilityState.getStream().subscribe((widgets) => {
        this.widgetsLocalVisibility = widgets;
        if (!this.initWidgetVisibility) {
          this.updateLocalVisibilityStorage();
        }
      })
    );
    this.subscriptions.add(
      this.widgetService
        .getWidgetContextItem(WidgetType.COORDINATES)
        .getStream()
        .subscribe((widget) => {
          this.position.projection = String(
            widget?.parameters.find((parameter) => parameter.id == ParameterId.DISPLAY_PROJ)?.value
          );
          this.position.template = String(
            widget?.parameters.find((parameter) => parameter.id == ParameterId.FORMAT)?.value
          );
          this.position.digits = Number(
            widget?.parameters.find((parameter) => parameter.id == ParameterId.DIGIT_NUMBER)?.value
          );
        })
    );
    this.subscriptions.add(
      this.selectionService.selectedFeatures
        .getStream()
        .pipe(debounceTime(100))
        .subscribe((selections) => {
          this.isFeatureSelected = selections?.some((selection) => selection.features.length) ?? false;
          if (
            this.isFeatureSelected &&
            this.activePanel !== 'featureDetails' &&
            this.activePanel !== 'editionValidation'
          ) {
            this.togglePanel('featureDetails');
          }
        })
    );
    this.subscriptions.add(
      this.qualih2oService.rpgLayer.activeAac$.subscribe((aac) => {
        if (aac) {
          this.togglePanel('widgetQualiH2O');
        }
      })
    );
    this.subscriptions.add(
      this.applicationApi.isSpecification.getStream().subscribe((state) => {
        if (state.opened) {
          this.handleSpecifierUser();
        }
      })
    );
  }

  ngOnInit(): void {
    const appId = this.route.snapshot.paramMap.get('id');
    this.geoserverVariables.reset();
    if (appId) {
      this.applicationApi.applicationLayers = [];

      this.authService.whoAmI().subscribe((user: User) => {
        if (user) {
          this.currentUser = user;
        }
        this.setupApplication(appId);
      });
    }
  }

  ngOnDestroy(): void {
    this.navigation.headerTitle.next(undefined);
    this.navigation.headerImage.next(undefined);
    this.widgetService.updateWidgetState();
    this.widgetService.widgetVisibilityState.reset();
    this.initWidgetVisibility = true;
    this.applicationApi.currentApplication.saveToBackup();
    this.applicationApi.currentApplication.setValue(undefined);
    this.applicationApi.settingsPanelState.setValue(undefined);
    this.applicationApi.isSpecification.reset();
    this.dialogSpecifier?.close(true);
    this.geoserverVariables.reset();
    this.authService.resetSpecifierUser();
    this.subscriptions.unsubscribe();
  }

  private setupApplication(appId: string) {
    this.applicationApi.getApplication(appId).subscribe({
      next: (app) => {
        this.application = app;
        this.configuration = app.parsedConfig;
        this.applicationApi.currentApplication.setValue(app);
        this.widgetService.updateWidgetState(this.configuration?.widgets);
        this.visibilityStorageKey = 'app-' + app.id + '-widgets-visibility';
        this.updateLocalVisibilityStorage();
        this.layerGroups = parseLayers(this.configuration.layers).map((group) => new DataLayerGroupConfig(group));
        rebuildZIndexes(this.layerGroups);

        const isSpecification = this.currentUser?.hasProfile(this.application, ProfileCode.SPECIFICATEUR) ?? false;
        if (this.olGmap) {
          this.mapService.configure(this.olGmap, this.configuration, isSpecification);
          this.mapService.createLayers(this.layerGroups);
        }
        if (app.displayedTitle) {
          this.navigation.headerTitle.next(app.nom);
        }
        if (app.logoName) {
          this.navigation.headerImage.next({
            id: app.id,
            name: app.logoName,
            baseUrl: this.applicationApi.getBaseUrl(),
          });
        } else {
          this.navigation.headerImage.next(undefined);
        }
        this.updateUserRights();
        this.checkLegendAvailable();
        this.toggleDefaultPanel();

        if (this.configuration.hasWidget(WidgetType.QUALIH2O)) {
          this.geolocation?.trackAndCenter();
        }

        if (isSpecification) {
          const layerCode = this.application?.parsedConfig.specification?.layerCode;
          if (layerCode) {
            if (this.application?.parsedConfig.specification?.hideLayers) {
              this.mapService.hideAllLayers([layerCode]);
            }
            this.mapService.getLayerByCode(layerCode).setLayerVisibility(true);
          }
          this.handleSpecifierUser(true);
        }
      },
      error: (error) => {
        if (error.status == 401) {
          this.notificationService.error(
            $localize`:Application Display|Error not accessible:Vous n'avez pas les autorisations nécessaires pour accéder à l'application demandée.`
          );
        } else if (error.status == 403) {
          this.notificationService.error(
            $localize`:Application Display|Error not connected:L'application à laquelle vous avez tenté d'accéder est privée. Veuillez vous connecter pour y accéder.`
          );
        } else if (error.status == 404) {
          this.notificationService.error(
            $localize`:Application Display|Error not found:L'application à laquelle vous avez tenté d'accéder n'existe pas.`
          );
        } else {
          this.notificationService.unknownServerError();
        }
        this.router.navigate(['']);
      },
    });
  }

  private handleSpecifierUser(init = false) {
    const panel = this.activePanel;
    if (this.activePanel) {
      this.togglePanel(this.activePanel);
    }
    this.widgetService.widgetVisibilityState.setParticularValue(WidgetType.LAYERS_MANAGER, Boolean(false));
    this.widgetService.widgetVisibilityState.setParticularValue(WidgetType.VIGIEVIROSE_ADMIN, Boolean(false));

    this.displayOverlay = true;
    this.dialogSpecifier = this.dialog.open(SpecifierInformationComponent, {
      data: {
        config: this.application?.parsedConfig.specification,
        appId: this.application?.id,
      },
      disableClose: true,
      hasBackdrop: false,
    });

    this.dialogSpecifier
      .afterClosed()
      .pipe(
        finalize(() => {
          this.displayOverlay = false;
          this.applicationApi.isSpecification.setParticularValue('opened', false);
        })
      )
      .subscribe((result: boolean) => {
        if (result) {
          this.displayOverlay = false;
          if (this.configuration && !init) {
            this.mapService.zoomTo(fromLonLat(this.configuration.initLocation), this.configuration.initZoom);
          }
          const layerCode = this.application?.parsedConfig.specification?.layerCode;
          if (layerCode) {
            const layer = this.mapService.getLayerByCode(layerCode);
            layer.isEditing = true;
            this.updateLayerEditionMode(layer);
            if (layer.config instanceof WfsLayerConfig && layer.olLayer?.getSource() instanceof VectorSource) {
              const source = layer.olLayer?.getSource() as VectorSource;
              // Let the time for the refresh of the source and to retrieve the features
              of(source.getFeatures()[0])
                .pipe(
                  delay(500),
                  map(() => {
                    if (isNil(source.getFeatures()[0])) {
                      throw false;
                    }
                    this.mapService.fitToLayer(layer.config, source.getExtent(), MapUtils.PROJECTION_MAP?.getCode());
                    of(source.getFeatures()[0])
                      .pipe(
                        delay(500),
                        map(() => {
                          if (isNil(source.getFeatures()[0])) {
                            throw false;
                          }
                          this.selectionService.addVectorFeatureToSelection(source.getFeatures()[0], layer);
                        }),
                        retry(10)
                      )
                      .subscribe();
                  }),
                  retry(10)
                )
                .subscribe();
            }
            if (init) {
              this.applicationApi.isSpecification.setParticularValue(
                'config',
                this.application?.parsedConfig.specification
              );
            }
          }
        } else {
          if (init) {
            this.router.navigate(['']);
          } else if (panel) {
            this.togglePanel(panel);
          }
        }
      });
  }

  private toggleDefaultPanel() {
    if (this.widgetsVisibility?.['syngenta-vigie-virose'] && this.widgetsLocalVisibility?.['syngenta-vigie-virose']) {
      this.togglePanel('widgetVigieVirose');
    } else if (this.widgetsVisibility?.['syngenta-icare'] && this.widgetsLocalVisibility?.['syngenta-icare']) {
      this.togglePanel('widgetIcare');
    } else if (
      this.widgetsVisibility?.['legend'] &&
      this.widgetsLocalVisibility?.['legend'] &&
      this.hasLegendAvailable
    ) {
      this.togglePanel('legend');
    }
  }

  togglePanel(name: keyof SidePanels): void {
    if (this.activePanel && name !== this.activePanel) {
      this.sidePanels[this.activePanel] = false;
    }
    if (
      this.activePanel === 'dataLayers' ||
      this.activePanel === 'widgetVigieVirose' ||
      this.activePanel === 'widgetIcare'
    ) {
      this.checkLegendAvailable();
    }
    this.sidePanels[name] = !this.sidePanels[name];
    this.panelTogglesVisible = !this.sidePanels[name];
    this.activePanel = this.sidePanels[name] ? name : undefined;
    if (!this.latestPanel) {
      this.activeLayer = undefined;
    }
  }

  toggleWidgetLocalVisibility(widgetId: WidgetType) {
    this.widgetService.widgetLocalVisibilityState.setParticularValue(
      widgetId,
      !this.widgetsLocalVisibility?.[widgetId]
    );
  }

  private updateLocalVisibilityStorage() {
    if (this.initWidgetVisibility) {
      const localConfig = localStorage.getItem(this.visibilityStorageKey);
      if (localConfig) {
        this.widgetService.widgetLocalVisibilityState.setValue(JSON.parse(localConfig));
      }
      this.initWidgetVisibility = false;
    } else {
      localStorage.setItem(this.visibilityStorageKey, JSON.stringify(this.widgetsLocalVisibility));
    }
  }

  private checkLegendAvailable() {
    const layers = this.mapService
      .getAllVisibleLayers()
      .filter((layer) => layer.config.isLegendDisplayed && layer.config.subtype !== LayerSubtype.HEATMAP);
    this.hasLegendAvailable = layers.length > 0;
  }

  displayLayerProperties(layer: DataLayerConfig): void {
    this.togglePanel('layerPropertyViewer');
    this.activeLayer = layer;
  }

  saveDisplay() {
    this.isSavingDisplay = true;
    if (this.application) {
      this.layerGroups.forEach((layer) => layer.updateDisplayParameters());
      const apiLayers = this.layerGroups.flatMap((group) => {
        group.updateConfigLayers();
        return group.config.toApiLayerGroup();
      });
      const updatedConfiguration = new ApplicationConfig(this.application.config);
      updatedConfiguration.layers = apiLayers;
      this.applicationApi
        .updateApplicationConfig(this.application, updatedConfiguration)
        .pipe(finalize(() => (this.isSavingDisplay = false)))
        .subscribe({
          next: () => {
            this.notificationService.success(
              $localize`La configuration a été mise à jour avec les paramètres d'affichage actuels.`
            );
          },
          error: () => {
            this.notificationService.error(
              $localize`La mise à jour de la configuration avec les paramètres d'affichage actuels a échouée.`
            );
          },
        });
    }
  }

  updateLayers(layers: DataLayerGroupConfig[]): void {
    this.layerGroups = layers;
    this.mapService.createLayers(layers);
  }

  onMeasureActiveChanged(type: MeasureType) {
    if (type == MeasureType.LENGTH && this.activeMeasure.length) {
      this.activeMeasure.area = false;
    } else if (type == MeasureType.AREA && this.activeMeasure.area) {
      this.activeMeasure.length = false;
    }
  }

  updateLayerEditionMode(layer: DataLayerConfig) {
    if (this.layerToEdit && layer.generatedId !== this.layerToEdit.generatedId) {
      this.layerToEdit.isEditing = false;
    }
    this.layerToValid = undefined;
    if (layer.config.editType) {
      const canEditGeometry = [LayerEditType.ALL, LayerEditType.GEOMETRIES, LayerEditType.MODIFY].includes(
        layer.config.editType
      );
      this.layerToEdit = layer.isEditing && canEditGeometry ? layer : undefined;
      if (layer.isEditing) {
        this.layerToValid = layer;
        this.interraScanService.activeDetailPopup.setValue(false);
      } else {
        this.interraScanService.activeDetailPopup.reset();
      }
    } else {
      this.layerToEdit = undefined;
      this.interraScanService.activeDetailPopup.reset();
    }
  }

  onMapReady(event: boolean) {
    this.mapReady = event;
    if (this.mapReady && this.configuration) {
      if (this.configuration.hasWidget(WidgetType.QUALIH2O)) {
        this.geolocation?.trackAndCenter();
      } else {
        this.mapService.zoomTo(fromLonLat(this.configuration.initLocation), this.configuration.initZoom);
      }
    }
  }

  private updateUserRights(): void {
    if (this.currentUser && this.application) {
      this.isUserApplicationAdmin = this.currentUser.canEdit(this.application, ProfileCode.ADMIN);
      this.canUserModifyLayer = this.currentUser.canEdit(this.application, [
        ProfileCode.SPECIFICATEUR,
        ProfileCode.EDITEUR,
        ProfileCode.VALID,
        ProfileCode.ADMIN,
      ]);
      this.canUserEditLayer = this.currentUser.canEdit(this.application, [
        ProfileCode.EDITEUR,
        ProfileCode.VALID,
        ProfileCode.ADMIN,
      ]);
      this.canUserValidateLayer = this.currentUser.canEdit(this.application, [ProfileCode.VALID, ProfileCode.ADMIN]);
    }
    if (this.isUserApplicationAdmin) {
      this.applicationApi.settingsPanelState.setValue(false);
    }
  }
}
