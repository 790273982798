import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { DialogService } from '@components/dialog/dialog.service';
import { TableComponent } from '@components/table/table.component';
import {
  ActionType,
  CellType,
  Column,
  DefaultAction,
  DefaultType,
  GeneralActions,
  SelectionAction,
} from '@components/table/table.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { MapService } from '@core/services/map.service';
import { NotificationService } from '@core/services/notification.service';
import { XlsxSheet, exportToXlsx } from '@core/utils/xlsx.utils';
import { InterraScanService } from '../interra-scan.service';
import { Parcel } from '../model/parcel.model';

@Component({
  standalone: true,
  selector: 'smv-interra-scan-parcel-stats',
  templateUrl: './parcel-stats.component.html',
  styleUrls: ['./parcel-stats.component.scss'],
  imports: [CommonModule, MatDialogModule, MatCheckboxModule, MatButtonModule, MatDividerModule, TableComponent],
})
export class ParcelStatsComponent implements OnInit {
  @Input() title = $localize`:InterraScan|Parcel overview:Récapitulatif des parcelles`;
  @Input() appId!: number;
  public parcelsList: Parcel[] = [];
  public selection: Parcel[] = [];
  public actionsConfig: GeneralActions<Parcel> = {};
  public columns: Column<Parcel, DefaultType<string>>[] = [];
  public paging: PagingInfo = new PagingInfo('interra-scan-parcel-list');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { appId: number },
    private interraScanService: InterraScanService,
    private mapService: MapService,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {
    this.appId = data.appId;
  }

  ngOnInit(): void {
    this.actionsConfig = {
      checkboxAllSelected: true,
      searchDisabled: false,
      deleteSelectedDisabled: false,
      deleteTooltip: $localize`:InterraScan|Delete parcels:Supprimer l'attribution de parcelles`,
      searchPlaceholder: $localize`:InterraScan|Parcel overview:Rechercher par parcelle/agriculteur/package/technicien`,
      refreshDisabled: true,
    };
    this.columns = [
      {
        field: 'parcelId',
        label: $localize`:Label|Parcel id:Parcelle n°`,
        type: CellType.String,
        cellData: 'parcelId',
        sort: true,
      },
      {
        field: 'parcelName',
        label: $localize`:Label|Parcel name:Parcelle`,
        type: CellType.String,
        cellData: 'parcelName',
        sort: true,
      },
      {
        field: 'farmer',
        label: $localize`:Label|Farmer:Agriculteur`,
        type: CellType.StringBis,
        cellData: 'farmer',
        cellDataBis: 'email',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'order',
        label: $localize`:Label|Order:Commande`,
        type: CellType.StringBis,
        cellData: 'order',
        cellDataBis: 'name',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'currentCrop',
        label: $localize`:Label|Current crop:Culture en place`,
        type: CellType.StringBis,
        allowCellEmpty: true,
        cellData: 'currentCrop',
        cellDataBis: 'label',
        sort: true,
      },
      {
        field: 'harvestDate',
        label: $localize`:Label|Harvest date:Date de récolte`,
        type: CellType.Date,
        dateFormat: 'dd/MM/yyyy',
        cellData: 'harvestDate',
        sort: true,
      },
      {
        field: 'nextCrop',
        label: $localize`:Label|Next crop:Culture suivante`,
        type: CellType.StringBis,
        allowCellEmpty: true,
        cellData: 'nextCrop',
        cellDataBis: 'label',
        sort: true,
      },
      {
        field: 'sowingDate',
        label: $localize`:Label|Sowing date:Date de semis`,
        type: CellType.Date,
        dateFormat: 'dd/MM/yyyy',
        cellData: 'sowingDate',
        sort: true,
      },
      {
        field: 'package',
        label: $localize`:Label|InterraScan package:Package`,
        type: CellType.StringBis,
        cellData: 'package',
        cellDataBis: 'label',
      },
      {
        field: 'physicalAccess',
        label: $localize`:Label|Parcel physical access:Commentaire accès physique`,
        type: CellType.String,
        cellData: 'physicalAccess',
      },
      {
        field: 'surface',
        label: $localize`:Label|Area:Surface (ha)`,
        type: CellType.String,
        cellData: 'surface',
        sort: true,
      },
      {
        field: 'modificationDate',
        label: $localize`Date modification`,
        type: CellType.String,
        cellData: 'modificationDate',
        hide: true,
      },
      {
        field: 'modificator',
        label: $localize`Modificateur`,
        type: CellType.StringBis,
        cellData: 'modificator',
        cellDataBis: 'email',
        allowCellEmpty: true,
        sort: true,
      },
      {
        field: 'actions',
        label: '',
        type: CellType.Action,
        cellData: '',
        actions: [
          {
            type: ActionType.Menu,
            tooltip: $localize`:Access tooltip:Plus d'actions`,
            identifier: 'id',
            menu: [
              {
                type: ActionType.Default,
                identifier: 'parcelId',
                icon: 'fit_screen',
                label: $localize`:Button:Zoomer sur la parcelle`,
                tooltip: $localize`:InterraScan|Zoom tooltip:Zoomer sur la parcelle`,
              },
              {
                type: ActionType.Delete,
                identifier: 'parcelId',
                label: $localize`:Button:Supprimer`,
                tooltip: $localize`:InterraScan|Delete tooltip:Supprimer l'attribution de cette parcelle`,
              },
            ],
          },
        ],
      },
    ];

    this.findParcels();
  }

  findParcels() {
    this.interraScanService.retrieveParcels(this.appId, this.paging).subscribe({
      next: (result) => {
        if (result.totalElements > 0) {
          this.parcelsList = result.content;
        } else {
          this.parcelsList = [];
        }
      },
      error: () => {
        this.notificationService.unknownServerError();
      },
    });
  }

  exportToCalc(parcels?: Parcel[]) {
    if (!parcels) {
      this.interraScanService
        .retrieveAllParcels(this.appId, this.paging)
        .subscribe((allParcels) => this.buildExportCalc(allParcels));
    } else {
      this.buildExportCalc(parcels);
    }
  }

  private buildExportCalc(parcels: Parcel[]) {
    const parcelsSheet: XlsxSheet = {
      name: $localize`:InterraScan|Export Sheet:Récapitulatif des parcelles`,
      headerRow: this.columns.map((column) => column.label),
      dataRows: this.getExportRows(parcels),
    };

    exportToXlsx($localize`:InterraScan|Export file:export_parcelles`, [parcelsSheet]);
  }

  private getExportRows(parcels: Parcel[]): string[][] {
    const result: string[][] = [];
    parcels.forEach((e) => {
      result.push([
        e.parcelId ?? '',
        e.parcelName ?? '',
        e.farmer.email ?? '',
        e.currentCrop?.label ?? '',
        String(e.harvestDate ?? ''),
        e.nextCrop?.label ?? '',
        String(e.sowingDate ?? ''),
        e.package.label,
        e.physicalAccess ?? '',
        String(e.surface ?? ''),
        String(e.modificationDate ?? ''),
        e.modificator.email ?? '',
      ]);
    });
    return result;
  }

  openConfirmDeleteDialog(deleteData: SelectionAction<string>) {
    if (deleteData.identifiers.length > 0) {
      this.dialogService.openParcelDeleteConfirm(deleteData.identifiers).subscribe((result: boolean) => {
        if (result) {
          this.interraScanService.deleteParcels(deleteData.ids).subscribe({
            next: () => {
              this.notificationService.success(
                $localize`:InterraScan|Parcel deletion successful:Les attributions des parcelles ont été correctement supprimés.`
              );
              this.findParcels();
            },
            error: (error: HttpErrorResponse) => {
              if (error.status == 400) {
                this.notificationService.error(
                  $localize`:InterraScan|Parcel not found:Une erreur est survenue, la parcelle n'a pas été trouvé. Veuillez réessayer ultérieurement ou prendre contact avec l'administrateur.`
                );
              } else {
                this.notificationService.unknownServerError();
              }
            },
          });
        }
      });
    }
  }

  zoomToParcel(data: DefaultAction) {
    this.interraScanService.retrieveParcelPosition(data.identifier).subscribe({
      next: (position) => {
        if (position?.geom) {
          this.mapService.fitToGeom(position?.geom);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.notificationService.error(error.error.ErrorResponse.message.split(';')[0]);
      },
    });
  }
}
