import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorMessages } from '@components/form-field-errors/form-error-messages';
import { ParameterId, WidgetModel } from '@core/model/application-api/widget.model';
import { MapUtils } from '@core/utils/map.utils';
import { Periodicity } from '@feature/client-app/widgets/timeline/timeline.model';
import { WidgetService } from '@feature/client-app/widgets/widgets.service';
import { dateRangeValidator } from './date-validator';

@Component({
  selector: 'smv-widget-parameters',
  templateUrl: './widget-parameters.component.html',
  styleUrls: ['./widget-parameters.component.scss'],
})
export class WidgetParametersComponent implements OnInit {
  @Input() widget!: WidgetModel;
  @Output() closeParametersPanel = new EventEmitter();

  private readonly DATE_PATTERN = Validators.pattern(/^(\d{4}-\d{2}-\d{2}|now(\s?[-+]\s?\d+)?)$/);

  public parametersForm = new FormArray<FormControl | FormGroup>([]);
  public displayProj = new FormControl<string>(MapUtils.PROJECTION_CODE_OPENLAYERS, Validators.required);
  public format = new FormControl<string>('Lat : {y}° Lon : {x}° (WGS84)', [
    Validators.required,
    Validators.maxLength(40),
  ]);
  public digitNumber = new FormControl<number>(4, [Validators.required, Validators.min(0), Validators.max(10)]);
  public resultCount = new FormControl<number>(5, [Validators.required, Validators.min(1), Validators.max(15)]);
  public stepDuration = new FormControl<number>(3000, [Validators.required, Validators.min(100)]);
  public periodicity = new FormControl<Periodicity>(Periodicity.WEEKS, Validators.required);
  public startDate = new FormControl<string>('now-7', [Validators.required, this.DATE_PATTERN]);
  public endDate = new FormControl<string>('now+7', [Validators.required, this.DATE_PATTERN]);
  public displayGraduation = new FormControl<boolean>(true, Validators.required);

  public readonly PeriodicityOptions = [
    { label: $localize`:Label|Periodicity:Jours`, value: Periodicity.DAYS },
    { label: $localize`:Label|Periodicity:Semaines`, value: Periodicity.WEEKS },
    { label: $localize`:Label|Periodicity:Mois`, value: Periodicity.MONTHS },
    { label: $localize`:Label|Periodicity:Années`, value: Periodicity.YEARS },
  ];

  public customErrors: ErrorMessages = {
    dateRangeInvalid: $localize`L'intervalle entre les dates est invalide`,
  };

  public ParameterId = ParameterId;

  constructor(private widgetService: WidgetService) {}

  ngOnInit() {
    this.initParametersControl();
  }

  private initParametersControl() {
    let countDateParameters = 0;
    this.widget.parameters.forEach((parameter) => {
      switch (parameter.id) {
        case ParameterId.DISPLAY_PROJ:
          this.displayProj.patchValue(String(parameter.value));
          this.parametersForm.push(this.displayProj);
          break;
        case ParameterId.FORMAT:
          this.format.patchValue(String(parameter.value));
          this.parametersForm.push(this.format);
          break;
        case ParameterId.DIGIT_NUMBER:
          this.digitNumber.patchValue(Number(parameter.value));
          this.parametersForm.push(this.digitNumber);
          break;
        case ParameterId.RESULT_COUNT:
          this.resultCount.patchValue(Number(parameter.value));
          this.parametersForm.push(this.resultCount);
          break;
        case ParameterId.LAYERNAME:
          //this.layername.patchValue(parameter.value as string[]);
          //this.parametersForm.push(new FormControl<string[]>([], Validators.required));
          break;
        case ParameterId.STEP_DURATION:
          this.stepDuration.patchValue(Number(parameter.value));
          this.parametersForm.push(this.stepDuration);
          break;
        case ParameterId.PERIODICITY:
          countDateParameters++;
          this.periodicity.patchValue(parameter.value as Periodicity);
          this.parametersForm.push(this.periodicity);
          break;
        case ParameterId.START_DATE:
          countDateParameters++;
          this.startDate.patchValue(String(parameter.value));
          this.parametersForm.push(this.startDate);
          break;
        case ParameterId.END_DATE:
          countDateParameters++;
          this.endDate.patchValue(String(parameter.value));
          this.parametersForm.push(this.endDate);
          break;
        case ParameterId.GRADUATION:
          this.displayGraduation.patchValue(Boolean(parameter.value));
          this.parametersForm.push(this.displayGraduation);
          break;
      }
    });
    if (countDateParameters === 3) {
      const timelineFormGroup = new FormGroup(
        {
          periodicity: this.periodicity,
          startDate: this.startDate,
          endDate: this.endDate,
        },
        [dateRangeValidator]
      );
      this.parametersForm.push(timelineFormGroup);
    }
  }

  patchWidget() {
    this.widget.parameters.forEach((parameter) => {
      switch (parameter.id) {
        case ParameterId.DISPLAY_PROJ:
          parameter.value = this.displayProj.value ?? MapUtils.PROJECTION_CODE_OPENLAYERS;
          break;
        case ParameterId.FORMAT:
          parameter.value = this.format.value ?? 'Lat : {y}° Lon : {x}° (WGS84)';
          break;
        case ParameterId.DIGIT_NUMBER:
          parameter.value = this.digitNumber.value ?? 4;
          break;
        case ParameterId.RESULT_COUNT:
          parameter.value = this.resultCount.value ?? 5;
          break;
        case ParameterId.LAYERNAME:
          //parameter.value = this.layername.value ?? '';
          break;
        case ParameterId.STEP_DURATION:
          parameter.value = this.stepDuration.value ?? 3000;
          break;
        case ParameterId.PERIODICITY:
          parameter.value = this.periodicity.value ?? Periodicity.WEEKS;
          break;
        case ParameterId.START_DATE:
          parameter.value = this.startDate.value ?? 'now-7';
          break;
        case ParameterId.END_DATE:
          parameter.value = this.endDate.value ?? 'now+7';
          break;
        case ParameterId.GRADUATION:
          parameter.value = this.displayGraduation.value ?? true;
          break;
      }
    });
  }

  applyParametersChanges() {
    if (this.parametersForm.valid) {
      this.patchWidget();
      this.widgetService.getWidgetContextItem(this.widget.widgetId).setValue(this.widget);
    }
  }
}
