<mat-form-field
  *ngIf="control"
  [class]="{ empty: isEmpty, disabled: control.disabled, 'hide-empty': !coerced['showEmptyWhenDisabled'] }"
  [subscriptSizing]="subscriptSizing"
  [floatLabel]="floatLabel"
>
  <mat-label *ngIf="!coerced['hideLabel']" class="unselectable">
    <ng-content select="[mat-label]" />
  </mat-label>

  <ng-container [ngSwitch]="controlType">
    <textarea
      *ngSwitchCase="'textarea'"
      matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="15"
      [required]="coerced['required']"
      [readonly]="coerced['readonly']"
      [formControl]="control"
    ></textarea>

    <mat-select *ngSwitchCase="'select'" [required]="coerced['required']" [formControl]="control">
      <mat-option *ngIf="!coerced['required']" [value]="null" i18n="Form Field|Reset select">Aucun</mat-option>
      <mat-option *ngFor="let option of formattedSelectOptions" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>

    <ng-container *ngSwitchCase="'autocomplete'">
      <input
        type="text"
        matInput
        [formControl]="control"
        [required]="coerced['required']"
        [matAutocomplete]="auto"
        (input)="autocompleteFilter(autocompleteInput.value)"
        #autocompleteInput
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn.bind(this)"
        [requireSelection]="coerced['requireSelection']"
      >
        <mat-option *ngIf="!coerced['required']" [value]="null" i18n="Form Field|Reset select">Aucun</mat-option>
        <mat-option *ngFor="let option of formattedSelectOptionsFiltered" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-autocomplete>
    </ng-container>

    <ng-container *ngSwitchDefault [ngSwitch]="type">
      <input
        *ngSwitchCase="'number'"
        matInput
        [required]="coerced['required']"
        type="number"
        [step]="step"
        [readonly]="coerced['readonly']"
        [formControl]="control"
      /><input
        *ngSwitchCase="'date'"
        matInput
        [required]="coerced['required']"
        type="date"
        [readonly]="coerced['readonly']"
        [formControl]="control"
      /><input
        *ngSwitchDefault
        matInput
        [required]="coerced['required']"
        [type]="type"
        [readonly]="coerced['readonly']"
        [formControl]="control"
      />
    </ng-container>
  </ng-container>

  <mat-hint *ngIf="coerced['hasHint']">
    <ng-content select="[mat-hint]" />
  </mat-hint>
  <mat-error>
    <smv-form-field-errors [field]="control" [messages]="errorMessages" />
  </mat-error>
  <smv-help-overlay matSuffix i18n *ngIf="coerced['hasHelp']" [closeAfter]="helpCloseAfter">
    <ng-content select="[help]" />
  </smv-help-overlay>

  <span matSuffix *ngIf="coerced['hasAction']">
    <ng-content select="[mat-icon-button]" />
  </span>
</mat-form-field>
