import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Communes } from '@core/model/communes.model';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import { toLonLat } from 'ol/proj';
import { Observable } from 'rxjs';
import { CommonService, ListWrapper } from './common.service';

@Injectable({ providedIn: 'root' })
export class CommunesService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/communes';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getAll(): Observable<Communes[]> {
    return this.pipeExtractList(this.http.get<ListWrapper<Communes>>(this.baseUrl));
  }

  convertFeatureToCommune(feature: Feature) {
    const geom = feature.getGeometry();
    let commune: { Communes: Communes } | undefined;
    if (geom != undefined) {
      const coords = toLonLat(getCenter(geom.getExtent()));
      this.getCommuneFromLonLat(coords);
    }
    return commune?.Communes;
  }
  getCommuneFromLonLat(lonlat: Coordinate): Observable<{ Communes: Communes }> {
    const params = {
      longitude: lonlat[0],
      latitude: lonlat[1],
    };
    return this.http.get<{ Communes: Communes }>(this.baseUrl + '/getCommuneAtCoordinates', { params });
  }
}
