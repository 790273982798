import { Injectable } from '@angular/core';
import { RPG_GEOSERVER_LAYER, kmlExportBaseUrl } from '@assets/widgets/qualih2o/qualih2o_base_config';
import { WmsLayerConfig } from '@core/model/layer-config.model';
import { MapService } from '@core/services/map.service';
import { GeoserverLayersUtils } from '@core/utils/geoserver-layers.utils';
import { LegendData, QualiH2oPdfHelper } from './helpers/qualih2o-pdf.helper';
import { Qualih2oService } from './qualih2o.service';

import { ApplicationApiService } from '@core/services/api/application-api.service';
import { Map as OlMap } from 'ol';
import TileWMS from 'ol/source/TileWMS';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Qualih2oExporterService {
  public readonly activeAac$ = new ReplaySubject<string | null>(1);

  public get mapInstance(): OlMap {
    return this.mapService.mapInstance;
  }

  constructor(
    private mapService: MapService,
    private qualih2oService: Qualih2oService,
    private applicationService: ApplicationApiService
  ) {}

  download(url: string, filename: string, token: string | null) {
    const options = token ? { headers: { Authorization: token } } : {};
    fetch(url, options)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }

  exportRpgToKML(): void {
    const extent = this.mapInstance.getView().calculateExtent(this.mapInstance.getSize());
    const parameters = GeoserverLayersUtils.wmsKmlDownloadUrlParameters(
      extent,
      RPG_GEOSERVER_LAYER,
      this.qualih2oService.rpgLayer.getActiveStyleName()
    );
    const appId = this.applicationService.currentApplication.getValue()?.id || 0;
    const exportUrl = kmlExportBaseUrl.replace('{appId}', String(appId)) + '&' + parameters;
    this.download(exportUrl, 'syngenta-rpg.kml', sessionStorage.getItem('Authorization'));
  }

  async exportToPdf(hasReports = false): Promise<void> {
    const legends: LegendData[] = this.mapService.layers$
      .getValue()
      .flatMap((group) => group.layers)
      .filter(
        (layer) =>
          layer.config.isLegendDisplayed &&
          layer.isDisplayed(this.mapService.getScale()) &&
          layer.config instanceof WmsLayerConfig &&
          layer.olLayer?.getSource() instanceof TileWMS
      )
      .map((layer) => {
        const config = layer.config as WmsLayerConfig;
        const layerSource = layer.olLayer?.getSource() as TileWMS;
        const urls =
          config.geoserverLayerName === RPG_GEOSERVER_LAYER
            ? this.qualih2oService.getRpgLegendUrl(config)
            : config.getLegendUrl(layerSource, this.mapService.getScale());
        return {
          url: Array.isArray(urls) ? urls[0] : urls,
          name: config.shortName ?? '',
        };
      });

    const canvas = await this.getCanvas();
    const pdfBuilder = new QualiH2oPdfHelper();
    await pdfBuilder.buildPdf(canvas, legends, this.mapService.getCurrentCoordinates(), hasReports);
  }

  private getCanvas(): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
      // From OL example https://openlayers.org/en/latest/examples/export-map.html
      this.mapInstance.once('rendercomplete', () => {
        const mapCanvas = document.createElement('canvas');
        const size = this.mapInstance.getSize() ?? [0, 0];
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext('2d');
        if (!mapContext) {
          reject();
          throw "Unable to get the canvas' context";
        }
        // Build the map canvas from all the layer's canvases
        this.mapInstance
          .getViewport()
          .querySelectorAll('.ol-layer canvas, canvas.ol-layer')
          .forEach((canvas) => {
            if (canvas instanceof HTMLCanvasElement && canvas.width > 0) {
              const canvasOpacity = canvas.style.opacity;
              mapContext.globalAlpha = canvasOpacity === '' ? 1 : +canvasOpacity;

              let matrix: number[] = [
                parseFloat(canvas.style.width) / canvas.width,
                0,
                0,
                parseFloat(canvas.style.height) / canvas.height,
                0,
                0,
              ];
              const matrixMatches = canvas.style.transform.match(/^matrix\(([^(]*)\)$/);
              if (matrixMatches?.length) {
                matrix = matrixMatches[1].split(',').map(Number);
              }
              mapContext.setTransform(matrix[0], matrix[1], matrix[2], matrix[3], matrix[4], matrix[5]);
              mapContext.drawImage(canvas, 0, 0);
            }
          });

        mapContext.globalAlpha = 1;
        mapContext.setTransform(1, 0, 0, 1, 0, 0);

        resolve(mapCanvas);
      });

      this.mapInstance.renderSync();
    });
  }
}
