import { Component } from '@angular/core';
import { VigieViroseService } from '../services/vigie-virose.service';

export enum VigieViroseDisplayMode {
  MODE_POINTS = 'MODE_POINTS',
  MODE_HEAT = 'MODE_HEAT',
  MODE_BOTH = 'MODE_BOTH',
  MODE_NONE = 'MODE_NONE',
}

@Component({
  selector: 'smv-vigie-virose-display-mode',
  templateUrl: './vigie-virose-display-mode.component.html',
  styleUrls: ['./vigie-virose-display-mode.component.scss'],
})
export class VigieViroseDisplayModeComponent {
  public title = $localize`Affichage`;

  public displayPoints = false;
  public displayHeat = false;

  constructor(private vigieViroseService: VigieViroseService) {
    const displayMode = this.vigieViroseService.filterState.getValue().displayMode;
    if (displayMode == VigieViroseDisplayMode.MODE_BOTH) {
      this.displayPoints = this.displayHeat = true;
    } else if (displayMode == VigieViroseDisplayMode.MODE_POINTS) {
      this.displayPoints = true;
    } else if (displayMode == VigieViroseDisplayMode.MODE_HEAT) {
      this.displayHeat = true;
    }
  }

  onDisplayModeChanged() {
    if (this.displayPoints && this.displayHeat) {
      this.vigieViroseService.filterState.setParticularValue<VigieViroseDisplayMode>(
        'displayMode',
        VigieViroseDisplayMode.MODE_BOTH
      );
    } else if (this.displayPoints) {
      this.vigieViroseService.filterState.setParticularValue<VigieViroseDisplayMode>(
        'displayMode',
        VigieViroseDisplayMode.MODE_POINTS
      );
    } else if (this.displayHeat) {
      this.vigieViroseService.filterState.setParticularValue<VigieViroseDisplayMode>(
        'displayMode',
        VigieViroseDisplayMode.MODE_HEAT
      );
    } else {
      this.vigieViroseService.filterState.setParticularValue<VigieViroseDisplayMode>(
        'displayMode',
        VigieViroseDisplayMode.MODE_NONE
      );
    }
  }
}
