import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { CommonService } from '@core/services/common.service';
import { NotificationService } from '@core/services/notification.service';
import { CNCNotification, ISNotification } from './notify-validation.model';

@Injectable({ providedIn: 'root' })
export class NotifyValidationService extends CommonService {
  constructor(
    protected override http: HttpClient,
    private applicationService: ApplicationApiService,
    private notificationService: NotificationService
  ) {
    super(http);
  }

  notifyVVTrapCreation(trapIds: number[]) {
    const appId = this.applicationService.currentApplication.getValue()?.id;
    if (appId) {
      const params = new HttpParams().append('appId', appId).append('trapIds', trapIds.join(','));
      this.http.get<void>(this.baseUrl + '/traps/notifyCreation', { params }).subscribe({
        next: () => {
          this.notificationService.success(
            $localize`:Validation Notification|Success:Un mail a été envoyé au(x) technicien(s) et au(x) créateur(s) du(des) piège(s) créé(s).`
          );
        },
        error: (error) => {
          if (error.status == HttpStatusCode.NotAcceptable) {
            this.notificationService.error(
              $localize`:Validation Notification|Error Not VV:Ce type de notification ne peut être envoyé que pour la fonctionnalité VigieViroseAdmin.`
            );
          }
          this.notificationService.unknownServerError();
        },
      });
    } else {
      this.notificationService.unknownServerError();
    }
  }

  notifyQCCNCValidation(proposalIds: string[], email: string, valid: boolean, content: string[]) {
    const body: CNCNotification = {
      proposalIds,
      email,
      valid,
      content,
    };
    this.http.post<void>(this.baseUrl + '/parcels/cnc/notify', body).subscribe({
      next: () => {
        this.notificationService.success(
          $localize`:Validation Notification|Success:Un mail a été envoyé à l'utilisateur de Quali'Cible qui a réalisé la proposition.`
        );
      },
      error: () => {
        this.notificationService.unknownServerError();
      },
    });
  }

  notifyISParcelModification(parcelData: ISNotification[]) {
    this.http.post<void>(this.baseUrl + '/interraScan/parcels/updateGeom', parcelData).subscribe({
      next: () => {
        this.notificationService.success(
          $localize`:Validation Notification|Success:Les informations des parcelles InterraScan ont été mises à jour.`
        );
      },
      error: () => {
        this.notificationService.unknownServerError();
      },
    });
  }
}
