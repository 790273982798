import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const req_token = sessionStorage.getItem('Authorization');
    let modifiedReq;
    if (
      (req.url.startsWith(environment.apiUrl) || req.url.indexOf('services-webapp-syngentamap') >= 0) &&
      !req.headers.get('Authorization') &&
      req_token
    ) {
      modifiedReq = req.clone({
        headers: req.headers.set('Authorization', req_token),
      });
    }
    return next.handle(modifiedReq || req).pipe(
      map((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          const res_token = event.headers.get('Authorization');
          if (res_token) {
            sessionStorage.setItem('Authorization', res_token);
          }
        }
        return event;
      })
    );
  }
}
