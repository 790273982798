import { Component } from '@angular/core';
import { GeoserverVariablesService } from '@widgets/widgets-filter-cql.service';
import { Recommendation } from '../../model/recommendation.model';
import { OptiSemisService } from '../../opti-semis.service';

@Component({
  selector: 'smv-opti-semis-recommendation-filter',
  templateUrl: './opti-semis-recommendation-filter.component.html',
  styleUrls: ['./opti-semis-recommendation-filter.component.scss'],
})
export class OptiSemisRecommendationFilterComponent {
  public recommendations: Recommendation[] = [];

  private filterAttr = 'recommendation_code';

  constructor(private geoserverVariables: GeoserverVariablesService, private optiSemisService: OptiSemisService) {
    this.optiSemisService.getRecommendations().subscribe((recommendations) => {
      recommendations.forEach((recommendation) => (recommendation.selected = true));
      this.recommendations = recommendations;
      this.computeFilter();
    });
  }

  private getSelectedJoinValues(): string {
    const values = this.recommendations.filter((filter) => filter.selected).map((filter) => filter.code);
    return values.join(`, `);
  }

  public computeFilter() {
    const filter = `${this.filterAttr} in (${this.getSelectedJoinValues()})`;
    this.geoserverVariables.optiSemisFilterState.setParticularValue('recommendation', filter);
  }
}
