<mat-tab-group *ngIf="layer" (selectedTabChange)="tabChanged($event)">
  <mat-tab label="Informations" i18n-label="Layer editor|Tab name">
    <smv-layer-info-tab [layer]="layer" (configUpdate)="infoPanelConfig = $event" [readonly]="readonly" />
  </mat-tab>
  <mat-tab *ngIf="hasAttributeTab" label="Attributs" i18n-label="Layer editor|Tab name">
    <smv-layer-attribute-tab [layer]="layer" [readonly]="readonly" (propertyUpdate)="layerProperties = $event" />
  </mat-tab>
  <mat-tab *ngIf="hasValidationTab && infoPanelConfig?.editable" label="Validation" i18n-label="Layer editor|Tab name">
    <smv-layer-validation-tab [layer]="layer" (validConfigUpdate)="validConfig = $event" />
  </mat-tab>
  <mat-tab *ngIf="hasTooltipTab" label="Infobulle" i18n-label="Layer editor|Tab name">
    <smv-layer-tooltip-tab
      [layer]="layer"
      [properties]="layerProperties"
      [readonly]="readonly"
      (tooltipUpdate)="tooltip = $event"
    />
  </mat-tab>
  <mat-tab *ngIf="hasLegendTab" label="Légende" i18n-label="Layer editor|Tab name">
    <smv-layer-legend-tab [layer]="layer" [readonly]="readonly" (legendUpdate)="legendConfig = $event" />
  </mat-tab>
  <mat-tab *ngIf="hasHeatMapTab" label="Carte de chaleur" i18n-label="Layer editor|Tab name">
    <smv-layer-heatmap-tab
      [layer]="layer"
      [properties]="layerProperties"
      [readonly]="readonly"
      (heatmapUpdate)="heatmapConfig = $event"
    />
  </mat-tab>
  <mat-tab *ngIf="hasStyleTab" label="Style" i18n-label="Layer editor|Tab name">
    <smv-layer-base-style-tab [layer]="layer" (styleUpdate)="styleUpdate = $event" />
  </mat-tab>
</mat-tab-group>

<div class="smv-sticky-bottom-actions" *ngIf="readonly; else editorActions">
  <button mat-stroked-button i18n="Button" (click)="closePanel.emit()">Fermer</button>
</div>

<ng-template #editorActions>
  <div class="smv-sticky-bottom-actions">
    <button mat-flat-button color="primary" *ngIf="hasStyleActions" i18n="Button" (click)="applyStyle()">
      Appliquer le style
    </button>
    <button mat-stroked-button i18n="Button" (click)="closeProperties()">Annuler</button>
    <div class="action-with-message">
      <button mat-flat-button color="primary" i18n="Button" [disabled]="!canSave" (click)="saveProperties()">
        Modifier
      </button>
      <smv-help-overlay *ngIf="!canSave" messageType="warning">{{ saveError }}</smv-help-overlay>
    </div>
    <div *ngIf="hasStyleActions && layer?.config?.type === 'WMS'">
      <button
        mat-flat-button
        color="primary"
        (click)="exportStyle()"
        [disabled]="layer?.config?.type != 'WMS'"
        i18n="Button"
      >
        Exporter le style
      </button>
    </div>
  </div>
</ng-template>
