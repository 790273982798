import { cloneDeep } from 'lodash-es';
import { FilterConfig } from './model/context-item-config.model';
import { CultivationGroup } from './model/cultivation.model';
import { Filter } from './model/filter.model';
import { VigieViroseDisplayMode } from './vigie-virose-display-mode/vigie-virose-display-mode.component';

interface VigieViroseConfig {
  [index: string]: boolean;
}

interface LayersCode {
  trap: string;
  heat: string;
}

export class VigieViroseLayerConfig {
  private readonly allLayers: VigieViroseConfig = {
    trap_aphid_pav_mav_rpv: false,
    heat_aphid_pav_mav_rpv: false,
    trap_aphid_cereals: false,
    heat_aphid_cereals: false,
    trap_leafhopper_wdv: false,
    heat_leafhopper_wdv: false,
    trap_leafhopper_cereals: false,
    heat_leafhopper_cereals: false,
    trap_aphid_bwyv_tumv_camv: false,
    heat_aphid_bwyv_tumv_camv: false,
    trap_aphid_rapeseed: false,
    heat_aphid_rapeseed: false,
    trap_leafhopper_rapeseed: false,
    heat_leafhopper_rapeseed: false,
    trap_sawfly_rapeseed: false,
    heat_sawfly_rapeseed: false,
    trap_fleabeetle_rapeseed: false,
    heat_fleabeetle_rapeseed: false,
    trap_simulation_aphid: false,
    heat_simulation_aphid: false,
  };

  private readonly observation = {
    CER: {
      puceron: {
        pav_mav_rpv: {
          trap: 'trap_aphid_pav_mav_rpv',
          heat: 'heat_aphid_pav_mav_rpv',
        },
        trap: 'trap_aphid_cereals',
        heat: 'heat_aphid_cereals',
      },
      cicadelle: {
        wdv: {
          trap: 'trap_leafhopper_wdv',
          heat: 'heat_leafhopper_wdv',
        },
        trap: 'trap_leafhopper_cereals',
        heat: 'heat_leafhopper_cereals',
      },
    },
    COL: {
      puceron: {
        bwyv_camv_tumv: {
          trap: 'trap_aphid_bwyv_tumv_camv',
          heat: 'heat_aphid_bwyv_tumv_camv',
        },
        trap: 'trap_aphid_rapeseed',
        heat: 'heat_aphid_rapeseed',
      },
      cicadelle: {
        trap: 'trap_leafhopper_rapeseed',
        heat: 'heat_leafhopper_rapeseed',
      },
      tenthrede: {
        trap: 'trap_sawfly_rapeseed',
        heat: 'heat_sawfly_rapeseed',
      },
      altise: {
        trap: 'trap_fleabeetle_rapeseed',
        heat: 'heat_fleabeetle_rapeseed',
      },
    },
  };

  private readonly simulation = {
    CER: {
      puceron: {
        trap: 'trap_simulation_aphid',
        heat: 'heat_simulation_aphid',
      },
    },
  };

  private selected: (keyof VigieViroseConfig)[] = [];

  public retrieveLayerCodeConfig(config: FilterConfig) {
    this.selected = [];
    this.computeSelectedLayersCode(config);

    const layersConfig = cloneDeep(this.allLayers);
    this.selected.forEach((layerCode) => (layersConfig[layerCode] = true));

    return layersConfig;
  }

  private computeSelectedLayersCode(config: FilterConfig) {
    const filter = config.dataFilter;
    if (config.displayFilter == 'FILTER_OBSERVATION') {
      this.getFromObservations(filter, config.displayMode);
    } else if (config.displayFilter == 'FILTER_SIMULATION') {
      this.getFromSimulations(filter, config.displayMode);
    }
  }

  private getFromObservations(filter: Filter, displayMode: VigieViroseDisplayMode) {
    if (filter.cultures && filter.insect) {
      if (this.isCereal(filter.cultures)) {
        if (filter.insect.code == 'puceron') {
          this.addLayerCode(this.observation.CER[filter.insect.code], displayMode);
          if (filter.virus && filter.virus.code == 'pav_mav_rpv') {
            this.addLayerCode(this.observation.CER[filter.insect.code][filter.virus.code], displayMode);
          }
        } else if (filter.insect.code == 'cicadelle') {
          this.addLayerCode(this.observation.CER[filter.insect.code], displayMode);
          if (filter.virus && filter.virus.code == 'wdv') {
            this.addLayerCode(this.observation.CER[filter.insect.code][filter.virus.code], displayMode);
          }
        }
      } else if (this.isColza(filter.cultures)) {
        if (filter.insect.code == 'puceron') {
          this.addLayerCode(this.observation.COL[filter.insect.code], displayMode);
          if (filter.virus && filter.virus.code == 'bwyv_camv_tumv') {
            this.addLayerCode(this.observation.COL[filter.insect.code][filter.virus.code], displayMode);
          }
        } else if (
          filter.insect.code == 'cicadelle' ||
          filter.insect.code == 'tenthrede' ||
          filter.insect.code == 'altise'
        ) {
          this.addLayerCode(this.observation.COL[filter.insect.code], displayMode);
        }
      }
    }
  }

  private getFromSimulations(filter: Filter, displayMode: VigieViroseDisplayMode) {
    if (filter.cultures && filter.insect) {
      if (this.isCereal(filter.cultures)) {
        if (filter.insect.code == 'puceron') {
          this.addLayerCode(this.simulation.CER[filter.insect.code], displayMode);
        }
      }
    }
  }

  private addLayerCode(layers: LayersCode, displayMode: VigieViroseDisplayMode) {
    if (displayMode == VigieViroseDisplayMode.MODE_BOTH) {
      this.selected.push(layers.trap, layers.heat);
    } else if (displayMode == VigieViroseDisplayMode.MODE_POINTS) {
      this.selected.push(layers.trap);
    } else if (displayMode == VigieViroseDisplayMode.MODE_HEAT) {
      this.selected.push(layers.heat);
    }
  }

  private isCereal(cultures: CultivationGroup) {
    return cultures.cultivations.find((cultivation) => ['BLE', 'ORG'].includes(cultivation.code)) != undefined;
  }

  private isColza(cultures: CultivationGroup) {
    return cultures.cultivations.find((cultivation) => ['COL'].includes(cultivation.code)) != undefined;
  }
}

export class VigieViroseAllTrapsConfig {
  private readonly allTraps = 'v_trap_flat';

  public getLayerCode() {
    return this.allTraps;
  }
}
