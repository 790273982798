<div class="smv-table-management">
  <div *ngIf="generalActions && !generalActions.tableOnly" [ngClass]="{ 'smv-page-layout': generalActions.pageLayout }">
    <div class="smv-actions">
      <button
        *ngIf="!generalActions.refreshDisabled"
        mat-mini-fab
        color="secondary"
        (click)="onRefresh()"
        matTooltip="Actualiser"
        i18n-matTooltip
      >
        <mat-icon>refresh</mat-icon>
      </button>

      <button
        *ngIf="!generalActions.addDisabled && generalActions.addTooltip"
        mat-mini-fab
        color="success"
        (click)="onAdd()"
        [matTooltip]="generalActions.addTooltip"
      >
        <mat-icon>{{ generalActions.addIcon }}</mat-icon>
      </button>

      <button
        *ngIf="!generalActions.deleteSelectedDisabled && generalActions.deleteTooltip"
        mat-mini-fab
        color="warn"
        (click)="askToDelete()"
        [matTooltip]="generalActions.deleteTooltip"
        [disabled]="!allSelected && !someSelected()"
      >
        <mat-icon>delete_forever</mat-icon>
      </button>

      <button
        *ngIf="
          !generalActions.actionSelectedDisabled &&
          generalActions.actionSelectedTooltip &&
          generalActions.actionSelectedIcon
        "
        mat-mini-fab
        color="secondary"
        (click)="onActionSelected()"
        [matTooltip]="generalActions.actionSelectedTooltip"
        [disabled]="!allSelected && !someSelected()"
      >
        <mat-icon>{{ generalActions.actionSelectedIcon }}</mat-icon>
      </button>

      <smv-search-field
        *ngIf="!generalActions.searchDisabled && generalActions.searchPlaceholder"
        class="search-field"
        [placeholder]="generalActions.searchPlaceholder"
        (search)="search($event)"
      >
      </smv-search-field>

      <div *ngIf="showUserFilter">
        <button mat-mini-fab color="success" [matMenuTriggerFor]="menu">
          <mat-icon>manage_search</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <form (click)="$event.stopPropagation()" #formFiltresRef>
            <div mat-menu-item>
              <mat-slide-toggle
                i18n="Slider|Is editor"
                name="slideInput"
                [(ngModel)]="inputSliderValues['editor']"
                (change)="search(this.searchInput)"
              >
                Est éditeur
              </mat-slide-toggle>
            </div>
            <div mat-menu-item>
              <mat-slide-toggle
                i18n="Slider|Is administrator"
                name="slideInput"
                [(ngModel)]="inputSliderValues['admin']"
                (change)="search(this.searchInput)"
              >
                Est administrateur
              </mat-slide-toggle>
            </div>
            <div mat-menu-item>
              <mat-slide-toggle
                i18n="Slider|Is right manager"
                name="slideInput"
                [(ngModel)]="inputSliderValues['rightAdmin']"
                (change)="search(this.searchInput)"
              >
                Est gestionnaire de droits
              </mat-slide-toggle>
            </div>
            <div mat-menu-item>
              <mat-slide-toggle
                i18n="Slider|Is reader"
                name="slideInput"
                [(ngModel)]="inputSliderValues['reader']"
                (change)="search(this.searchInput)"
              >
                Est lecteur
              </mat-slide-toggle>
            </div>
            <div mat-menu-item>
              <mat-slide-toggle
                i18n="Slider|Is validator"
                name="slideInput"
                [(ngModel)]="inputSliderValues['validator']"
                (change)="search(this.searchInput)"
              >
                Est validateur
              </mat-slide-toggle>
            </div>
            <div mat-menu-item>
              <mat-slide-toggle
                i18n="Slider|Is specifier"
                name="slideInput"
                [(ngModel)]="inputSliderValues['specifier']"
                (change)="search(this.searchInput)"
              >
                Est spécificateur
              </mat-slide-toggle>
            </div>
            <mat-divider></mat-divider>
            <div mat-menu-item>
              <mat-slide-toggle
                i18n="Slider|Is super admin"
                name="slideInput"
                [(ngModel)]="inputSliderValues['superAdmin']"
                (change)="search(this.searchInput)"
              >
                Est super administrateur
              </mat-slide-toggle>
            </div>
          </form>
        </mat-menu>
      </div>

      <button
        *ngIf="
          !generalActions.rightActionDisabled && generalActions.rightActionTooltip && generalActions.rightActionIcon
        "
        mat-mini-fab
        color="secondary"
        (click)="openRightAction()"
        [matTooltip]="generalActions.rightActionTooltip"
      >
        <mat-icon>{{ generalActions.rightActionIcon }}</mat-icon>
      </button>
    </div>
  </div>

  <div class="smv-list">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      [matSortDisabled]="sortDisabled"
      (matSortChange)="sortData($event)"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            [checked]="allSelected"
            color="primary"
            [indeterminate]="someSelected()"
            (change)="selectAll($event.checked)"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row; index as i">
          <mat-checkbox
            color="accent"
            *ngIf="!(generalActions?.hideCheckboxCondition && generalActions?.hideCheckboxCondition(row))"
            [(ngModel)]="task.subtasks[i].selected"
            (ngModelChange)="updateAllSelected()"
          ></mat-checkbox>
        </td>
      </ng-container>
      <ng-container *ngFor="let column of columnsDef" matColumnDef="{{ column.field }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sort">
          {{ column.label }}
          <smv-help-overlay *ngIf="column.helpText">
            <div>{{ column.helpText }}</div>
          </smv-help-overlay>
        </th>
        <ng-container [ngSwitch]="column.type">
          <ng-container *ngSwitchCase="CellType.Boolean">
            <td mat-cell *matCellDef="let row" class="text-nowrap">
              <ng-container *ngIf="!row[column.cellData]">
                <mat-icon>check_box_outline_blank</mat-icon>
              </ng-container>
              <ng-container *ngIf="row[column.cellData]">
                <mat-icon>check_box</mat-icon>
              </ng-container>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="CellType.BooleanLabels">
            <td mat-cell *matCellDef="let row" class="text-nowrap">
              {{ row[column.cellData] === true ? column.cellDataTrue : column.cellDataFalse }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="CellType.String">
            <td mat-cell *matCellDef="let row" class="text-nowrap">
              <mat-icon *ngIf="column.cellDataBis && row[column.cellDataBis]">verified_user</mat-icon>
              {{ row[column.cellData] }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="CellType.StringBis">
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row[column.cellData] && column.cellDataBis; else emptyCellTpl">
                {{ row[column.cellData][column.cellDataBis] }}
              </ng-container>
              <ng-template #emptyCellTpl>
                <ng-container *ngIf="column.allowCellEmpty; else loadTpl"></ng-container>
              </ng-template>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="CellType.StringTer">
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row[column.cellData] && column.cellDataBis && column.cellDateTer; else emptyCellTpl">
                {{ row[column.cellData][column.cellDataBis][column.cellDateTer] }}
              </ng-container>
              <ng-template #emptyCellTpl>
                <ng-container *ngIf="column.allowCellEmpty; else loadTpl"></ng-container>
              </ng-template>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="CellType.StringSmall">
            <td mat-cell *matCellDef="let row" class="text-nowrap text-small">
              <mat-icon *ngIf="column.cellDataBis && row[column.cellDataBis]">verified_user</mat-icon>
              {{ row[column.cellData] }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="CellType.Date">
            <td mat-cell *matCellDef="let row" class="text-nowrap">
              {{ row[column.cellData] | date : column.dateFormat }}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="CellType.Select">
            <td mat-cell *matCellDef="let row">
              <mat-select
                [(value)]="row[column.cellData].id"
                (selectionChange)="onChange(row)"
                *ngIf="column.select"
                [disabled]="column.select.disabled && column.select.disabled(row)"
                panelClass="select-panel"
              >
                <mat-option
                  *ngFor="let element of column.select.list"
                  [value]="element.id"
                  [disabled]="column.select.hideOptionCondition && column.select.hideOptionCondition(row, element)"
                >
                  {{ element[column.select.identifier] }}
                </mat-option>
              </mat-select>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="CellType.Img">
            <td mat-cell *matCellDef="let row">
              <img
                *ngIf="row.logoName; else noLogoBlock"
                src="{{ column.cellData }}/{{ row.id }}/logo"
                [alt]="row.logoName"
                width="60em"
                title="{{ row.logoName }}"
              />
              <ng-template #noLogoBlock><em></em></ng-template>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="CellType.Visibility">
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="!row[column.cellData]">
                <mat-icon>lock</mat-icon>
              </ng-container>
              <ng-container *ngIf="row[column.cellData]">
                <mat-icon>lock_open</mat-icon>
              </ng-container>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="CellType.Action">
            <td mat-cell *matCellDef="let row" class="sticky-right">
              <ng-container *ngTemplateOutlet="rowActions; context: { $implicit: column.actions, row: row }" />
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !generalActions?.stickyDisabled"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div class="empty-grid-text" *ngIf="!dataSource.data.length" i18n="No data">Aucune donnée à afficher.</div>
  </div>
  <smv-paging *ngIf="!paginatorDisabled" [pagingInfo]="pagingInfo" (pageChange)="onRefresh()" />
</div>

<ng-template #loadTpl i18n="Table|Loadind data">Récupération des données...</ng-template>

<ng-template #rowActions let-actions let-row="row">
  <ng-container *ngFor="let action of actions">
    <ng-container [ngSwitch]="action.type" *ngIf="!(action.hide && action.hideCondition && action.hideCondition(row))">
      <ng-container *ngSwitchCase="ActionType.Router">
        <button mat-icon-button color="primary" [routerLink]="[action.link, row.id]" [matTooltip]="action.tooltip">
          <mat-icon>{{ action.icon }}</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ActionType.Menu">
        <button
          mat-icon-button
          [matTooltip]="action.tooltip"
          [matMenuTriggerFor]="actionMenu"
          [matMenuTriggerData]="{ row: row, actions: action.menu }"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ActionType.Default">
        <button
          mat-stroked-button
          (click)="onDefaultAction(row[action.identifier], action.key)"
          [matTooltip]="action.tooltip"
          matTooltipPosition="before"
        >
          <mat-icon>{{ action.icon }}</mat-icon>
          <span>{{ action.label }}</span>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #rowMenuActions let-actions let-row="row">
  <ng-container *ngFor="let action of actions">
    <ng-container [ngSwitch]="action.type" *ngIf="!(action.hide && action.hideCondition && action.hideCondition(row))">
      <ng-container *ngSwitchCase="ActionType.Modify">
        <button mat-menu-item (click)="onModify(row.id)" [matTooltip]="action.tooltip" matTooltipPosition="before">
          <mat-icon>edit_user</mat-icon>
          <span>{{ action.label }}</span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ActionType.Delete">
        <button
          mat-menu-item
          (click)="onDelete([row[action.identifier]], [row.id])"
          [matTooltip]="action.tooltip"
          matTooltipPosition="before"
        >
          <mat-icon>delete</mat-icon>
          <span>{{ action.label }}</span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ActionType.Default">
        <button
          mat-menu-item
          (click)="onDefaultAction(row[action.identifier], action.key)"
          [matTooltip]="action.tooltip"
          matTooltipPosition="before"
        >
          <mat-icon>{{ action.icon }}</mat-icon>
          <span>{{ action.label }}</span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ActionType.Router">
        <button mat-menu-item [routerLink]="[action.link, row.id]">
          <mat-icon>{{ action.icon }}</mat-icon>
          <span>{{ action.label }}</span>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<mat-menu #actionMenu="matMenu" xPosition="before">
  <ng-template matMenuContent let-actions="actions" let-row="row">
    <ng-container *ngTemplateOutlet="rowMenuActions; context: { $implicit: actions, row: row }" />
  </ng-template>
</mat-menu>
