import { LoadedImage, PdfElement } from './pdf-builder-types';

export const pxToMm = (pixels: number): number => {
  // jsPdf's default setting is 72dpi = 2.834646 dpm
  const dpm = 2.834646;
  return pixels / dpm;
};

export const fitElementToSize = (element: PdfElement, maxWidth: number, maxHeight: number): PdfElement => {
  if (element.width < maxWidth) {
    maxWidth = element.width;
  }

  if (element.height < maxHeight) {
    maxHeight = element.height;
  }

  const ratio = getRatio(element, maxWidth, maxHeight);
  return {
    width: element.width / ratio,
    height: element.height / ratio,
  };
};

export const getBlobSize = (blob: Blob): Promise<PdfElement> => {
  return new Promise((resolve) => {
    const img = document.createElement('img');
    img.src = URL.createObjectURL(blob);
    img.onload = () => resolve({ width: img.width, height: img.height });
  });
};

export const getExternalImage = (url: string): Promise<LoadedImage> => {
  const token = sessionStorage.getItem('Authorization');
  const options = token ? { headers: { Authorization: token } } : {};
  return fetch(url, options).then(async (response) => {
    const blob = await response.blob();
    const blobSize = await getBlobSize(blob);
    const buffer = await blob.arrayBuffer();
    return { data: new Uint8Array(buffer), ...blobSize };
  });
};

export const getRatio = (
  element: HTMLCanvasElement | PdfElement,
  availableWidth: number,
  availableHeight: number
): number => {
  const heightRatio = element.height / availableHeight;
  const widthRatio = element.width / availableWidth;
  return heightRatio > widthRatio ? heightRatio : widthRatio;
};
