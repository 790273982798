import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedResult, PaginatedResultWrapper } from '@core/model/paginated-result.model';
import { PagingInfo } from '@core/model/paging-info.model';
import { Position } from '@core/model/position.model';
import { User, UserWrapper } from '@core/model/user.model';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

export interface ListWrapper<T> {
  List: T[];
}

export interface BooleanWrapper {
  Boolean: boolean;
}

export interface PositionWrapper {
  Position: Position;
}

@Injectable({ providedIn: 'root' })
export class CommonService {
  protected readonly baseUrl = environment.apiUrl + '/rest';

  constructor(protected http: HttpClient) {}

  deleteEntities(entities: number[]): Observable<unknown> {
    let params = new HttpParams();
    params = params.appendAll({ ids: entities });

    return this.http.delete(this.baseUrl, { params });
  }

  sendListQuery<T>(pagingInfo?: PagingInfo, url?: string, modifyUrl = true): Observable<PaginatedResult<T>> {
    let params = new HttpParams();
    if (!url) {
      url = this.baseUrl;
    }
    if (pagingInfo) {
      if (pagingInfo.filters.length) {
        if (modifyUrl) {
          url += '/search';
        }
        params = pagingInfo.setPageFilters(params);
      }
      params = pagingInfo.setPageParams(params);
    } else {
      pagingInfo = new PagingInfo();
    }

    return this.pipePaginated(this.http.get<PaginatedResultWrapper<T>>(url, { params }), pagingInfo);
  }

  pipePaginated<T>(obs: Observable<PaginatedResultWrapper<T>>, pagingInfo: PagingInfo): Observable<PaginatedResult<T>> {
    return obs.pipe(
      map((result: PaginatedResultWrapper<T>) => {
        pagingInfo.nbTotalRes = result.PageImpl.totalElements;
        return result.PageImpl;
      })
    );
  }

  getBaseUrl() {
    return this.baseUrl;
  }

  protected pipeExtractUser(obs: Observable<UserWrapper>): Observable<User> {
    return obs.pipe(map((result: UserWrapper) => new User(result.User)));
  }

  protected pipeExtractList<T>(obs: Observable<ListWrapper<T>>): Observable<T[]> {
    return obs.pipe(
      map((result: ListWrapper<T>) => {
        return result.List;
      })
    );
  }

  protected pipeExtractBoolean(obs: Observable<BooleanWrapper>): Observable<boolean> {
    return obs.pipe(
      map((result: BooleanWrapper) => {
        return result.Boolean;
      })
    );
  }

  protected pipeExtractPosition(obs: Observable<PositionWrapper>): Observable<Position> {
    return obs.pipe(
      map((result: PositionWrapper) => {
        return result.Position;
      })
    );
  }
}
