import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldAppearance, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DefaultType } from '@components/table/table.model';
import { GeneralUtils, KeysOfType } from '@core/utils/general.utils';
import { ReplaySubject, filter, tap } from 'rxjs';

@Component({
  selector: 'smv-multi-select',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
  ],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent<T extends DefaultType<number> | string> implements OnInit {
  public filtered: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
  public searchControl: FormControl<string | null> = new FormControl<string | null>('');

  @Input() label = '';
  @Input() placeholder = '';
  @Input() hint?: string;
  @Input() identifier?: KeysOfType<T, string>;
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() displayRow: (element: T) => string = (element: T) => (typeof element == 'string' ? element : '');
  @Input() displayRowEm: (element: T) => string = () => '';
  @Input() searchCondition: (search: string) => boolean = () => true;
  @Input() filterFonction?: (search: string) => T[];
  @Input() serverFilterFonction?: (search: string, filtered: ReplaySubject<T[]>) => void;

  @Input() selected: T[] = [];
  @Output() selectedChange = new EventEmitter<T[]>();

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        tap(() => this.filtered.next([])),
        filter(GeneralUtils.isNotNull),
        filter((search) => this.searchCondition(search))
      )
      .subscribe((search) => {
        if (this.filterFonction) {
          this.filtered.next(this.filterFonction(search));
        } else if (this.serverFilterFonction) {
          this.serverFilterFonction(search, this.filtered);
        }
      });
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    if (!this.selected.includes(event.option.value)) {
      this.selected.push(event.option.value);
      this.onAction();
    }
  }

  displayChip(element: T): string {
    if (this.identifier) {
      return element[this.identifier] as string;
    }
    if (typeof element == 'string') {
      return element;
    }
    return '';
  }

  remove(element: T): void {
    const index = this.selected.indexOf(element);

    if (index >= 0) {
      this.selected.splice(index, 1);
      this.onAction();
    }
  }

  onAction() {
    this.selectedChange.emit(this.selected);
  }
}
