import { FormControl, FormGroup, Validators } from '@angular/forms';
import { dateAfterValidator, dateBeforeValidator } from '@components/form/date-validator';
import { AttributeType, LayerProperty } from '@core/model/application-api/layer.model';
import { isNil } from 'lodash-es';
import { Feature } from 'ol';
import { Indexable } from '../general.utils';

interface CoordAttributes {
  lat?: FormControl<number | null>;
  long?: FormControl<number | null>;
}

export class FeatureAttributesParser {
  public form = new FormGroup({} as Indexable<FormControl>);
  public savedForm = new FormGroup({} as Indexable<FormControl>);

  public attributes: LayerProperty[] = [];
  public coordAttributes: CoordAttributes = {};

  private feature: Feature;
  private layerProperties?: LayerProperty[];

  constructor(feature: Feature, layerProperties?: LayerProperty[], useDefault = true) {
    this.feature = feature;
    this.layerProperties = layerProperties;

    this.parseFeature(this.feature, useDefault);
  }

  private parseFeature(feature: Feature, useDefault: boolean) {
    const keys = feature.getKeys();

    if (this.layerProperties) {
      const displayabledProperties = this.layerProperties.filter((property) => property.display);
      displayabledProperties.forEach((attribute) => {
        const control = this.getFormControl(attribute.type);
        const savedControl = this.getFormControl(attribute.type);
        if (control && savedControl) {
          this.setValidators(control, attribute);
          const defaultValue =
            useDefault && attribute.configuration?.defaultValues['default']?.length
              ? attribute.configuration.defaultValues['default'][0]
              : null;
          if (attribute.type === AttributeType.DATE || attribute.type === AttributeType.DATE_TIME) {
            const value = this.getValue(attribute.name, defaultValue);
            if (value) {
              control.setValue(value.split('Z')[0].split('T')[0]);
            }
          } else if (attribute.type === AttributeType.BOOLEAN) {
            const defaultBoolean = attribute.configuration?.defaultBoolean ?? false;
            control.setValue(this.getValue(attribute.name, defaultBoolean));
          } else {
            control.setValue(this.getValue(attribute.name, defaultValue));
          }
          this.checkCoord(attribute, control);
          this.attributes.push(attribute);
          this.form.addControl(attribute.name, control);
          this.savedForm.addControl(attribute.name, savedControl);
        }
      });
    } else {
      keys?.forEach((key) => {
        const control = this.getFormControl();
        const savedControl = this.getFormControl();
        if (control && savedControl) {
          control.setValue(this.getValue(key));
          this.attributes.push({ name: key, label: key, type: AttributeType.STRING, nillable: true } as LayerProperty);
          this.form.addControl(key, control);
          this.savedForm.addControl(key, savedControl);
        }
      });
    }
  }

  private getFormControl(type = AttributeType.STRING) {
    switch (type) {
      case AttributeType.STRING:
        return new FormControl<string | null>(null);
      case AttributeType.BOOLEAN:
        return new FormControl<boolean>(false);
      case AttributeType.NUMBER:
      case AttributeType.DOUBLE:
      case AttributeType.INT:
        return new FormControl<number | null>(null);
      case AttributeType.DATE:
      case AttributeType.DATE_TIME:
        return new FormControl<Date | null>(null);
      default:
        return;
    }
  }

  private setValidators(control: FormControl, attribute: LayerProperty) {
    const min = attribute.configuration?.min;
    const max = attribute.configuration?.max;
    const dateMin = attribute.configuration?.dateMin;
    const dateMax = attribute.configuration?.dateMax;
    const pattern = attribute.configuration?.pattern;

    const numberType =
      attribute.type === AttributeType.NUMBER ||
      attribute.type === AttributeType.DOUBLE ||
      attribute.type === AttributeType.INT;

    if (!isNil(min)) {
      if (numberType) {
        control.addValidators(Validators.min(min));
      } else {
        control.addValidators(Validators.minLength(min));
      }
    }
    if (!isNil(max)) {
      if (numberType) {
        control.addValidators(Validators.max(max));
      } else {
        control.addValidators(Validators.maxLength(max));
      }
    }
    if (!isNil(pattern)) {
      control.addValidators(Validators.pattern(pattern));
    }
    if (!isNil(dateMin)) {
      control.addValidators(dateAfterValidator(dateMin));
    }
    if (!isNil(dateMax)) {
      control.addValidators(dateBeforeValidator(dateMax));
    }
    control.updateValueAndValidity();
  }

  private checkCoord(attribute: LayerProperty, control: FormControl) {
    if (
      attribute.configuration?.defaultValues['default']?.[0] === '{LATITUDE}' &&
      [AttributeType.NUMBER, AttributeType.INT, AttributeType.DOUBLE].includes(attribute.type)
    ) {
      this.coordAttributes.lat = control as FormControl<number | null>;
    }
    if (
      attribute.configuration?.defaultValues['default']?.[0] === '{LONGITUDE}' &&
      [AttributeType.NUMBER, AttributeType.INT, AttributeType.DOUBLE].includes(attribute.type)
    ) {
      this.coordAttributes.long = control as FormControl<number | null>;
    }
  }

  static getInputType(type: AttributeType) {
    switch (type) {
      case AttributeType.STRING:
        return 'text';
      case AttributeType.NUMBER:
      case AttributeType.DOUBLE:
      case AttributeType.INT:
        return 'number';
      case AttributeType.DATE:
      case AttributeType.DATE_TIME:
        return 'date';
      default:
        return '';
    }
  }

  public reset() {
    this.form.patchValue(this.savedForm.value);
  }

  private getValue(propertyName: string, defaultValue: string | number | boolean | null = null) {
    return this.feature.get(propertyName) ?? defaultValue;
  }
}
